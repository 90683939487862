import { makeStyles } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as CheckMarkGreen } from '../assets/svg/checkMarkGreen.svg'
import { ReactComponent as CheckMarkRed } from '../assets/svg/checkMarkRed.svg'
import { ReactComponent as CloseMarkGreen } from '../assets/svg/closeMarkGreen.svg'
import { ReactComponent as CloseMarkRed } from '../assets/svg/closeMarkRed.svg'
import {
  OBJECT,
  showLess,
  showMore,
  timeScore,
  totalFitScore,
  uid,
} from '../utils/constant'
import {
  capitalizeString,
  isEmptyString,
  replaceChar,
  ternary,
  typeOf,
} from '../utils/javascript'
import RPCTooltip from './elements/RPCTooltip'
import InfoIcon from './icons/InfoIcon'

export const useStyles = makeStyles(() => ({
  displayFlex: {
    display: 'flex',
  },
  inlineSpace: {
    marginInline: 4,
  },
  tooltipTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  mainList: {
    fontSize: 16,
    fontWeight: 500,
  },
  fitScoreTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  lightTooltipsList: {
    borderTop: 'none !important',
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
  },
  listTitle: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    paddingLeft: 14,
    '& svg': {
      fontSize: 18,
      textAlign: 'center',
      alignItems: 'center',
      height: 24,
    },
  },
  subList: {
    paddingTop: '4px !important',
    paddingBottom: '0px !important',
    marginLeft: 24,
    maxWidth: '180px',
    '& div': {
      maxWidth: 130,
      minWidth: 130,
    },
  },
  labelsData: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 12,
    paddingRight: 0,
  },
  requireTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 208,
    alignItems: 'center',
  },
  requireScoreGreen: {
    color: 'green',
    border: '0.5px solid green',
  },
  requireScore: {
    padding: [[2, 6]],
    minWidth: 33,
    textAlign: 'center',
    fontWeight: 500,
  },
  requireScoreGray: {
    color: '#000',
    backgroundColor: '#e4e4e4',
    fontSize: 10,
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
  boxStyle: {
    width: 25,
    height: 25,
    marginLeft: 10,
    textAlign: 'center',
    padding: '3px 0px',
    color: 'white',
    fontWeight: 500,
  },
  totalScoreTooltip: {
    fontSize: 13,
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      marginLeft: '10px',
    },
  },
  fitScoreBox: {
    marginLeft: '30px',
    color: 'green',
    border: '0.5px solid green',
    padding: [[0, 8]],
  },
  totalFitScoreBox: {
    width: '35px !important',
    height: '35px !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerItem: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalFitScoreTitle: {
    fontSize: '22px !important',
    fontWeight: '500 !important',
  },
  leftSideSpace: {
    marginLeft: '10px !important',
  },
  showMoreBtn: {
    color: '#64A2DC',
    fontSize: 14,
    marginLeft: 24,
    paddingTop: 4,
    border: 'none',
    backgroundColor: 'white',
    paddingLeft: 0,
  },
  categoryScoreBox: {
    width: '30px !important',
    height: '30px !important',
    fontWeight: '500 !important',
    fontSize: '16px !important',
  },
  scoreTooltip: {
    padding: 4,
    margin: 0,
    listStyle: 'none',
    '& li': {
      padding: '8px 0',
      fontWeight: '400',
      fontSize: 14,
      borderTop: '1px solid rgba(255,255,255,0.5)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '100%',
      '& span': {
        marginLeft: '10px',
        marginRight: 5,
      },
    },
  },
  scoreTooltipWrapper: {
    padding: 4,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fitScoreDiv: {
    position: 'relative',
    '& .apexcharts-yaxis': {
      display: 'none',
    },
    '& .apexcharts-legend-marker': {
      position: 'static',
    },
  },
  scores: {
    position: 'absolute',
    top: 70,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: 12,
    textAlign: 'center',
    width: 165,
    '& span': {
      color: '#fff',
      padding: [[0, 2]],
    },
  },
  chartLabel: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      '&:not(:first-child)': {
        marginLeft: 5,
        minWidth: 25,
      },
      '&:first-child': {
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'right',
      },
    },
    '&:first-child': {
      '& span': {
        flex: 'none !important',
        marginLeft: 'auto',
      },
    },
  },
  chartTooltip: {
    position: 'fixed !important',
  },
  chartLabelDetail: {
    color: '#000 !important',
    height: 'fit-content',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 'auto 0',
    width: '100%',
  },
  chartLabelDetailGreen: {
    color: 'rgb(80, 172, 154) !important',
    height: 'fit-content',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 'auto 0',
    width: '100%',
  },
  underlined: {
    textDecoration: 'underline',
  },
  chartLabelDetailRed: {
    color: '#ff0000 !important',
    height: 'fit-content',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 'auto 0',
    width: '100%',
  },
  tooltipBodyHeight: {
    maxHeight: 400,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#f98e35',
      borderRadius: 2,
    },
  },
  tooltipPaper: {
    '& .MuiTooltip-tooltip': {
      fontSize: 12,
    },
  },
}))

export const getCards = (value) => {
  if (value <= 50) {
    return '#DD3B4E'
  }
  if (value <= 80 && value >= 50) {
    return '#ff7400'
  }
  return '#50AC9A'
}

const getLabelInfo = (label) => {
  let str = ''
  if (label?.isBookingLabel && label?.isPlanningLabel) {
    str = 'Talent, Job & Work Opportunity Label'
  } else if (label?.isBookingLabel) {
    str = 'Talent & Work Opportunity Label'
  } else {
    str = 'Talent & Job Label'
  }
  return str
}

const RPCColorBox = ({ value, tableMeta, formPath }) => {
  const classes = useStyles()
  const [showMoreObj, setShowMoreObj] = useState({})
  const { parent, child, childObject, childObj, ...props } = formPath
  const data = useSelector((state) =>
    ternary(
      childObject,
      state?.api?.[parent]?.[child]?.[childObject],
      state?.api?.[parent]?.[child],
    ),
  )
  const allInLower = /^[a-z]/
  const detail = (data?.items || data)
    ?.find(
      ({ id, originalId }) =>
        id === tableMeta?.rowData[0] || originalId === tableMeta?.rowData[0],
    )
    ?.[childObj]?.[props?.scoreKey || 'categoryScores']?.map((obj) => ({
      ...obj,
      name: allInLower.test(obj?.name)
        ? capitalizeString(replaceChar(obj?.name))
        : obj?.name,
    }))
  const Children = ({ value, className = '' }) => (
    <div
      className={`${classes.boxStyle} ${className}`}
      style={{
        backgroundColor: ternary(
          typeOf(value, OBJECT),
          !isEmptyString(value?.[props?.totalKey]),
          !isEmptyString(value),
        )
          ? getCards(
              ternary(typeOf(value, OBJECT), value?.[props?.totalKey], value),
            )
          : '#e4e4e4',
        ...(ternary(
          typeOf(value, OBJECT),
          isEmptyString(value?.[props?.totalKey]),
          isEmptyString(value),
        ) && { color: '#000000' }),
      }}
    >
      {ternary(
        typeOf(value, OBJECT),
        isEmptyString(value?.[props?.totalKey])
          ? '-'
          : Math.round(value?.[props?.totalKey]),
        isEmptyString(value) ? '-' : Math.round(value),
      )}
    </div>
  )

  const RenderLabelMatch = ({ label, match, name }) => (
    <div>
      <li className={classes.subList}>
        <div>{label?.name}</div>
        {name === 'REQUIRED' || name === 'OPTIONAL' ? (
          match ? (
            <CheckMarkGreen />
          ) : (
            <CloseMarkRed />
          )
        ) : match ? (
          <CheckMarkRed />
        ) : (
          <CloseMarkGreen />
        )}
        <RPCTooltip
          style={{
            height: '50%',
            textAlign: 'left',
            verticalAlign: 'middle',
          }}
          classes={{ popper: classes.tooltipPaper }}
          title={
            <>
              <div>Category: {label?.label_category?.name || ''}</div>
              <div>Type: {getLabelInfo(label)}</div>
            </>
          }
        >
          <span>
            <InfoIcon
              style={{
                height: 15,
                width: 15,
              }}
            />
          </span>
        </RPCTooltip>
      </li>
    </div>
  )
  const RenderLabelCount = ({ labelMatches, name }) => (
    <>
      {labelMatches?.length ? (
        name === 'REQUIRED' || name === 'OPTIONAL' ? (
          `${labelMatches?.filter(({ match }) => !!match)?.length} / ${
            labelMatches?.length
          } `
        ) : (
          `${labelMatches?.filter(({ match }) => !match)?.length} / ${
            labelMatches?.length
          } `
        )
      ) : (
        <span className={classes.inlineSpace}>
          <RemoveIcon />
        </span>
      )}
    </>
  )

  const handleToggleShowMore = (e, name) => {
    const cloneShowMore = { ...showMoreObj }
    cloneShowMore[name] = !showMoreObj[name]
    setShowMoreObj(cloneShowMore)
    e.stopPropagation()
  }
  return (
    <span className={classes.displayFlex}>
      {ternary(
        typeOf(value, OBJECT),
        !isEmptyString(value?.[props?.totalKey]),
        !isEmptyString(value),
      ) ? (
        <>
          <RPCTooltip
            options={{ interactive: true }}
            lightTooltip
            title={
              <div className={classes.tooltipBodyHeight}>
                <ul className={classes.scoreTooltip}>
                  <li key={uid()} className={`${classes.lightTooltipsList}`}>
                    <div
                      className={`${classes.mainList} ${classes.totalFitScoreTitle}`}
                    >
                      {tableMeta?.columnData?.label === timeScore ? (
                        timeScore
                      ) : (
                        <>
                          {totalFitScore}
                          <RPCTooltip
                            style={{
                              height: '50%',
                              textAlign: 'left',
                              verticalAlign: 'middle',
                            }}
                            title="Only considers Booking Type 'C'"
                          >
                            <span>
                              <InfoIcon
                                style={{ height: 20, marginLeft: 0, width: 26 }}
                              />
                            </span>
                          </RPCTooltip>
                        </>
                      )}
                    </div>
                    {Children({
                      value,
                      className: `${classes.totalFitScoreBox} ${classes.totalFitScoreTitle} ${classes.displayFlex}`,
                    })}
                  </li>
                  {detail?.map(({ name, score, subCategoryScores = [] }) => (
                    <React.Fragment key={uid()}>
                      <li
                        className={`${classes.lightTooltipsList} ${classes.leftSideSpace}`}
                      >
                        <div className={classes.mainList}>{name}</div>
                        {Children({
                          value: score,
                          className: `${classes.displayFlex} ${classes.centerItem} ${classes.categoryScoreBox}`,
                        })}
                      </li>
                      {subCategoryScores?.length
                        ? subCategoryScores?.map(
                            ({ name, score, labelMatches = [] }) => (
                              <div
                                key={`${uid()}${name}`}
                                className={classes.leftSideSpace}
                              >
                                <li className={classes.labelsData}>
                                  <div className={classes.requireTitle}>
                                    <div className={classes.listTitle}>
                                      {name?.toLowerCase()}
                                    </div>
                                    <span className={classes.listTitle}>
                                      {RenderLabelCount({
                                        labelMatches,
                                        name,
                                      })}
                                    </span>
                                  </div>
                                  {Children({
                                    value: score,
                                    className: `${classes.displayFlex} ${classes.centerItem}`,
                                  })}
                                </li>
                                {labelMatches?.length
                                  ? labelMatches
                                      ?.sort((a, b) =>
                                        a?.label?.name > b?.label?.name
                                          ? 1
                                          : -1,
                                      )
                                      ?.map(({ label, match }, index) => {
                                        if (index > 2) {
                                          return (
                                            <React.Fragment key={index}>
                                              {showMoreObj[name] && (
                                                <RenderLabelMatch
                                                  label={label}
                                                  name={name}
                                                  match={match}
                                                />
                                              )}
                                              {index ===
                                              labelMatches?.length - 1 ? (
                                                <button
                                                  onClick={(e) =>
                                                    handleToggleShowMore(
                                                      e,
                                                      name,
                                                    )
                                                  }
                                                  type="button"
                                                  className={`${classes.showMoreBtn} ${classes.cursorPointer}`}
                                                >
                                                  {ternary(
                                                    showMoreObj[name],
                                                    showLess,
                                                    showMore,
                                                  )}
                                                </button>
                                              ) : (
                                                ''
                                              )}
                                            </React.Fragment>
                                          )
                                        }
                                        return (
                                          <React.Fragment key={index}>
                                            <RenderLabelMatch
                                              label={label}
                                              name={name}
                                              match={match}
                                            />
                                          </React.Fragment>
                                        )
                                      })
                                  : ''}
                              </div>
                            ),
                          )
                        : ''}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            }
          >
            <span>{Children({ value })}</span>
          </RPCTooltip>
        </>
      ) : (
        Children({ value })
      )}
    </span>
  )
}

export default RPCColorBox
